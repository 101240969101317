import { useState } from 'react'
import { classNames } from '../../../../helpers/classNames'
import { useTracking } from '../../../Shared/Hooks/TrackingHook'
import EbayLogo from '../../../Shared/Images/EbayLogo.svg'
import { asset } from '../../../Shared/asset'
import { Select } from '../../Components/Select/Select'
import { Arrow } from '../../Images/Arrow'
import TireDesktop from './Images/TireFinderTireDesktop.png'
import TireMobile from './Images/TireFinderTireMobile.png'
import {
    diameterOptions,
    mapTireTypeOption,
    tireCrossSectionOptions,
    tireSpeedOptions,
    tireTypeOptions,
    tireWidthOptions,
} from './TireFinderOptions'

export enum TIRE_TYPE {
    WINTER = 'CarWinter',
    SUMMER = 'CarSummer',
    ALL_YEAR = 'CarAllyear',
}

export type TireFinderInterface = {
    teaserDesignIndexSite: boolean
    variant: TIRE_TYPE
}

export default function TireFinderTeaser({ teaserDesignIndexSite, variant }: TireFinderInterface) {
    const [isClicked, setIsClicked] = useState(false)
    const [tireWidth, setTireWidth] = useState<string>('205')
    const [tireCrossSection, setTireCrossSection] = useState<string>('55')
    const [diameter, setDiameter] = useState<string>('16')
    const [tireType, setTireType] = useState<string>(mapTireTypeOption(variant))
    const [tireSpeed, setTireSpeed] = useState<string>('')

    const affiliateType = `reifen-finder-${tireTypeOptions
        .find((o) => o.value === mapTireTypeOption(variant))
        ?.name.toLocaleLowerCase()}`

    const { trackingRef, trackClick } = useTracking({
        event_name: 'affiliate',
        event_data: {
            affiliate_type: affiliateType,
            affiliate_merchant: 'ebay',
        },
    })

    const url = new URL(
        `https://www.ebay.de/sch/Reifen/179680/i.html?${new URLSearchParams({
            _dmpt: 'Auto_Reifen_2',
            mkcid: '1',
            mkrid: '707-53477-19255-0',
            siteid: '77',
            campid: '5338976947',
            toolid: '10001',
            mkevt: '1',
            LH_ItemCondition: '1000|1500',
            Reifenbreite: tireWidth,
            Reifenquerschnitt: tireCrossSection,
            'Zollgr%C3%B6%C3%9Fe': diameter,
            Reifenspezifikation: tireType,
            Geschwindigkeitsindex: tireSpeed,
        })}`,
    )

    const clickHandler = () => {
        setIsClicked(true)

        trackClick({})
    }

    return (
        <div
            data-testid="teaser-tire-finder"
            className={classNames(
                'md:w-1010',
                teaserDesignIndexSite ? 'mb-40 mt-36 md:mt-41' : 'my-34 md:mx-teaser-xl-aubi md:-mt-9 md:mb-32',
            )}
            ref={trackingRef}
        >
            {teaserDesignIndexSite ? (
                <div className="mb-16 flex w-full items-end justify-between font-slab md:mb-15">
                    <div
                        className="-mb-2 text-24 font-extrabold leading-[3.165rem] text-tarmac-500 md:mb-0 md:text-28 md:leading-36"
                        data-testid="separateHeadline"
                    >
                        Reifen-Finder
                    </div>
                    <span className="mb-5 text-11 leading-12 text-tarmac-200 md:-mb-2 md:leading-17">ANZEIGE</span>
                </div>
            ) : (
                <span className="-mt-14 mb-14 flex w-full justify-end font-slab text-11 leading-12 text-tarmac-200 md:leading-17">
                    ANZEIGE
                </span>
            )}

            <fieldset
                className={classNames(
                    'relative -mt-10 flex flex-col border-solid border-tarmac-75 font-slab',
                    teaserDesignIndexSite ? 'border-t-1' : 'border-1 p-12 md:-mt-11 md:p-19',
                )}
            >
                {!teaserDesignIndexSite && (
                    <legend className="absolute -top-15 left-13 -ml-7 bg-white px-6 text-18 font-bold md:left-16 md:px-10 md:uppercase">
                        Reifen-Finder
                    </legend>
                )}

                <div
                    className={classNames(
                        'bg-tarmac-25 px-12 pb-12 md:px-20 md:pt-14',
                        teaserDesignIndexSite ? 'px-18 pb-18 md:pb-19' : 'md:pb-10',
                    )}
                >
                    <div
                        className={classNames(
                            'absolute z-10 md:inline-flex',
                            teaserDesignIndexSite
                                ? 'right-19 top-8 md:right-20 md:top-16'
                                : 'right-26 top-16 md:right-39 md:top-35 md:w-[23rem] md:text-right',
                        )}
                    >
                        <span className="mr-0 text-right font-slab text-12 font-normal text-tarmac-200 md:ml-auto md:w-auto md:pt-10">
                            In Kooperation mit
                        </span>
                        <img
                            className="-mr-2 -mt-2 mb-0 ml-auto h-25 w-auto md:mb-0 md:ml-5 md:mr-0 md:mt-5"
                            src={asset(EbayLogo)}
                            height="25"
                            width="59"
                            alt="Ebay Logo"
                        />
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <div
                            className={classNames(
                                'flex w-full flex-col items-end',
                                teaserDesignIndexSite
                                    ? 'pr-12 md:relative md:order-2 md:w-[15.7rem] md:pr-0'
                                    : 'md:relative md:order-2',
                            )}
                        >
                            <img
                                className={classNames(
                                    'h-auto max-md:mx-auto max-md:max-w-[17rem] md:h-[16.8rem] md:w-[13.8rem]',
                                    teaserDesignIndexSite ? 'mt-15 w-[41.335%] md:mt-34' : 'mt-21 w-[43.36%] md:mt-32',
                                )}
                                src={asset(TireMobile, { width: 300 })}
                                srcSet={`${asset(TireDesktop, { width: 138 })} 138w, ${asset(TireMobile, {
                                    width: 150,
                                })} 150w, ${asset(TireMobile, { width: 170 })} 170w`}
                                sizes="(max-width: 449px) 150px, (max-width: 767px) 170px, 138px"
                                alt="reifen.com"
                                loading="lazy"
                                width="300"
                                height="368"
                            />
                        </div>

                        <div className="flex flex-col md:order-1">
                            <p
                                className={classNames(
                                    'mb-8 w-full px-15 text-center font-extrabold md:mb-10 md:mt-1 md:px-0 md:text-left md:text-24 md:leading-30',
                                    teaserDesignIndexSite ? 'mt-0 text-22 leading-30' : 'mt-2 text-20 leading-28',
                                )}
                            >
                                Passende Markenreifen online kaufen &amp; sparen!
                            </p>

                            <div className="mb-12 flex flex-row flex-wrap items-end md:mb-11">
                                <Select
                                    label="Reifenmaße"
                                    className={classNames(
                                        'w-1/3 max-md:mb-12',
                                        teaserDesignIndexSite
                                            ? 'pr-[1.25rem] md:w-[13.75rem] md:pr-[1.55rem]'
                                            : 'pr-[1.55rem] md:w-[13.15rem]',
                                    )}
                                    options={tireWidthOptions}
                                    value={tireWidth}
                                    stateDispatcher={setTireWidth}
                                    data-testid="tireWidthSelect"
                                />

                                <Select
                                    label=""
                                    className={classNames(
                                        'w-1/3 max-md:mb-12',
                                        teaserDesignIndexSite
                                            ? 'pr-[1.25rem] md:w-[13.75rem] md:pr-[1.55rem]'
                                            : 'pr-[1.55rem] md:w-[13.15rem]',
                                    )}
                                    options={tireCrossSectionOptions}
                                    value={tireCrossSection}
                                    stateDispatcher={setTireCrossSection}
                                    data-testid="tireCrossSectionSelect"
                                />

                                <Select
                                    label=""
                                    className={classNames(
                                        'w-1/3 max-md:mb-12 md:mb-0',
                                        teaserDesignIndexSite ? 'md:w-122' : 'md:w-[11.6rem]',
                                    )}
                                    options={diameterOptions}
                                    value={diameter}
                                    stateDispatcher={setDiameter}
                                    data-testid="diameterSelect"
                                />

                                <Select
                                    label="Reifentyp"
                                    className={classNames(
                                        'w-full md:mb-0 md:w-[37.8rem]',
                                        teaserDesignIndexSite
                                            ? 'mb-1 md:ml-20 md:w-[39.6rem]'
                                            : 'mb-0 md:ml-19 md:w-[37.8rem]',
                                    )}
                                    options={tireTypeOptions}
                                    value={tireType}
                                    stateDispatcher={setTireType}
                                    data-testid="tireTypeSelect"
                                />
                            </div>
                            <div className="flex flex-col md:flex-row">
                                <Select
                                    label="Geschwindigkeitsindex"
                                    className={classNames(
                                        'mb-18 w-full md:mb-1',
                                        teaserDesignIndexSite ? 'md:w-[39.6rem]' : 'md:w-[37.8rem]',
                                    )}
                                    options={tireSpeedOptions}
                                    value={tireSpeed}
                                    stateDispatcher={setTireSpeed}
                                    data-testid="tireSpeedSelect"
                                    placeholder="Alle"
                                />

                                <div
                                    className={classNames(
                                        'flex w-full md:ml-20 md:items-end md:justify-end',
                                        teaserDesignIndexSite ? 'md:w-[39.6rem]' : 'md:w-[37.8rem]',
                                    )}
                                >
                                    <a
                                        data-testid="ctaButton"
                                        className={classNames(
                                            isClicked ? 'bg-red-500' : 'bg-tarmac-400',
                                            'flex h-[4.6rem] w-full flex-row items-center justify-center border-0 border-solid p-10 text-14 font-bold uppercase text-white hover:bg-red-light-400 md:h-[5.7rem] md:w-[20.788rem] md:text-18',
                                            !teaserDesignIndexSite && 'md:mb-0',
                                        )}
                                        href={url.toString()}
                                        onClick={clickHandler}
                                        target="_blank"
                                        rel="noopener nofollow"
                                    >
                                        <span className="mr-9 leading-20 md:mr-12 md:leading-29">Reifen finden</span>
                                        <Arrow className="h-20 w-10 text-14 md:h-29 md:w-15 md:text-16" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    )
}
