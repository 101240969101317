import type { DropdownOption } from '../../Components/Select/Select'
import { TIRE_TYPE } from './TireFinderTeaser.component'
/*  Reifenbreite */
export const tireWidthOptions: DropdownOption[] = [
    { name: '115', value: '115' },
    { name: '120', value: '120' },
    { name: '125', value: '125' },
    { name: '135', value: '135' },
    { name: '140', value: '140' },
    { name: '145', value: '145' },
    { name: '155', value: '155' },
    { name: '165', value: '165' },
    { name: '175', value: '175' },
    { name: '180', value: '180' },
    { name: '185', value: '185' },
    { name: '190', value: '190' },
    { name: '195', value: '195' },
    { name: '205', value: '205' },
    { name: '215', value: '215' },
    { name: '225', value: '225' },
    { name: '235', value: '235' },
    { name: '245', value: '245' },
    { name: '255', value: '255' },
    { name: '265', value: '265' },
    { name: '275', value: '275' },
    { name: '285', value: '285' },
    { name: '295', value: '295' },
    { name: '305', value: '305' },
    { name: '315', value: '315' },
    { name: '325', value: '325' },
    { name: '335', value: '335' },
    { name: '345', value: '345' },
    { name: '355', value: '355' },
]

/*  Reifenquerschnitt */
export const tireCrossSectionOptions: DropdownOption[] = [
    { name: '25', value: '25' },
    { name: '30', value: '30' },
    { name: '35', value: '35' },
    { name: '40', value: '40' },
    { name: '45', value: '45' },
    { name: '50', value: '50' },
    { name: '55', value: '55' },
    { name: '60', value: '60' },
    { name: '65', value: '65' },
    { name: '70', value: '70' },
    { name: '75', value: '75' },
    { name: '80', value: '80' },
    { name: '85', value: '85' },
    { name: '90', value: '90' },
    { name: '95', value: '95' },
]
/*  Zoll */
export const diameterOptions: DropdownOption[] = [
    { name: 'R8', value: '8' },
    { name: 'R9', value: '9' },
    { name: 'R10', value: '10' },
    { name: 'R12', value: '12' },
    { name: 'R13', value: '13' },
    { name: 'R14', value: '14' },
    { name: 'R15', value: '15' },
    { name: 'R16', value: '16' },
    { name: 'R16.5', value: '16%2C5' },
    { name: 'R17', value: '17' },
    { name: 'R17.5', value: '17%2C5' },
    { name: 'R18', value: '18' },
    { name: 'R19', value: '19' },
    { name: 'R19.5', value: '19%2C5' },
    { name: 'R20', value: '20' },
    { name: 'R21', value: '21' },
    { name: 'R22', value: '22' },
    { name: 'R22.5', value: '22%2C5' },
    { name: 'R23', value: '23' },
    { name: 'R24', value: '24' },
    { name: 'R25', value: '25' },
]

/*  Typ des Reifens */
export const tireTypeOptions: DropdownOption[] = [
    { name: 'Sommerreifen', value: 'Sommerreifen' },
    { name: 'Ganzjahresreifen', value: 'Ganzjahresreifen' },
    { name: 'Winterreifen', value: 'Winterreifen' },
]

export function mapTireTypeOption(type: TIRE_TYPE): string {
    switch (type) {
        case TIRE_TYPE.ALL_YEAR:
            return 'Ganzjahresreifen'
        case TIRE_TYPE.SUMMER:
            return 'Sommerreifen'
        case TIRE_TYPE.WINTER:
            return 'Winterreifen'
    }
}

/*  Geschwindigkeitsindex */
export const tireSpeedOptions: DropdownOption[] = [
    { name: 'Beliebig', value: '' },
    { name: 'C: bis 60 km/h', value: 'C%3A%20bis%2060%20km%2Fh' },
    { name: 'D: bis 65 km/h', value: 'D%3A%20bis%2065%20km%2Fh' },
    { name: 'E: bis 70 km/h', value: 'E%3A%20bis%2070%20km%2Fh' },
    { name: 'F: bis 80 km/h', value: 'F%3A%20bis%2080%20km%2Fh' },
    { name: 'G: bis 90 km/h', value: 'G%3A%20bis%2090%20km%2Fh' },
    { name: 'J: bis 100 km/h', value: 'J%3A%20bis%20100%20km%2Fh' },
    { name: 'K: bis 110 km/h', value: 'K%3A%20bis%20110%20km%2Fh' },
    { name: 'L: bis 120 km/h', value: 'L%3A%20bis%20120%20km%2Fh' },
    { name: 'M: bis 130 km/h', value: 'M%3A%20bis%20130%20km%2Fh' },
    { name: 'N: bis 140 km/h', value: 'N%3A%20bis%20140%20km%2Fh' },
    { name: 'P: bis 150 km/h', value: 'P%3A%20bis%20150%20km%2Fh' },
    { name: 'Q: bis 160 km/h', value: 'Q%3A%20bis%20160%20km%2Fh' },
    { name: 'R: bis 170 km/h', value: 'R%3A%20bis%20170%20km%2Fh' },
    { name: 'S: bis 180 km/h', value: 'S%3A%20bis%20180%20km%2Fh' },
    { name: 'T: bis 190 km/h', value: 'T%3A%20bis%20190%20km%2Fh' },
    { name: 'U: bis 200 km/h', value: 'U%3A%20bis%20200%20km%2Fh' },
    { name: 'H:  bis 210 km/h', value: 'H%3A%20bis%20210%20km%2Fh' },
    { name: 'V: bis 240 km/h', value: 'V%3A%20bis%20240%20km%2Fh' },
    { name: 'Z: über 240 km/h', value: 'Z%3A%20%C3%BCber%20240%20km%2Fh' },
    { name: 'W: bis 270 km/h', value: 'W%3A%20bis%20270%20km%2Fh' },
    { name: 'Y: bis 300 km/h', value: 'Y%3A%20bis%20300%20km%2Fh' },
]
